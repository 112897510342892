import axios from 'axios';

const baseURL = 'https://api.persicom.ru';

export const postMetrics = async (encrypted_id, metrics) => {
  const response = await axios.post(
    `${baseURL}/stats/collect_metrics/?encrypted_id=${encrypted_id}`,
    metrics,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  return response;
};

export const postVideoStats = async (watch_time, short_link) => {
  const response = await axios.post(
    `${baseURL}/stats/collect_video_stats/?watch_time=${watch_time}&short_link=${short_link}`
  );
  return response;
};

export const postClicksStats = async (encrypted_id, clicks) => {
  const response = await axios.post(
    `${baseURL}/stats/collect_clicks/?encrypted_id=${encrypted_id}`,
    clicks
  );
  return response;
};
