import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import Deflogo from './logo.png';
import poster from './poster.png';
import './Player.css';
import { StyledButton } from './StyledButton';
import { postMetrics, postVideoStats, postClicksStats } from '../api/api';
import { IconPlay } from './IconPlay';
import { IconPause } from './IconPause';

const Player = () => {
  const videoRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [videoBlobUrl, setVideoBlobUrl] = useState(null); 
  const [targetButtons, setTargetButtons] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [logo, setLogo] = useState(Deflogo);
  const [styles, setStyles] = useState(null);
  const [posterUrl, setPosterUrl] = useState(null);
  const [metrics, setMetrics] = useState({
    date: '',
    time: '',
    video_url: '',
    pers_parameters: []
  });
  const [encryptId, setEncryptId] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showControls, setShowControls] = useState(true);

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const date = new Date();
        const optionsDate = {
          timeZone: 'Europe/Moscow',
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        };
        const optionsTime = {
          timeZone: 'Europe/Moscow',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        };
        const moscowDate = new Intl.DateTimeFormat('en-CA', optionsDate).format(date);
        const moscowTime = new Intl.DateTimeFormat('ru-RU', optionsTime).format(date);
        const formattedDate = moscowDate.replace(/\./g, '-');
        const formattedTime = moscowTime.replace(',', '');
        setShowPopup(true);
        const shortLink = window.location.search.substring(1);

        if (shortLink) {
          const params = { short_link: shortLink };
          

          const response = await axios.post(
            `https://api.persicom.ru/projects/generate_custom_video`,
            params,
            { responseType: 'blob' } 
          );


          const blob = new Blob([response.data], { type: 'video/mp4' });
          const videoUrl = URL.createObjectURL(blob);
          setVideoBlobUrl(videoUrl);

          const metadataResponse = await axios.get(
            `https://api.persicom.ru/projects/get_video_metadata/${shortLink}`
          );

          const responseData = metadataResponse.data;

          setTargetButtons(responseData.target_buttons);
          setTitle(responseData.title);
          setDescription(responseData.description);
          setStyles(responseData.styles);
          setPosterUrl(responseData.poster_url);
          document.title = responseData.project_name || 'Default Project Name';
          setLogo(responseData.logo_url || Deflogo);
          setEncryptId(responseData.encrypt_id);
          setMetrics({
            ...metrics,
            video_url: window.location.href,
            pers_parameters: responseData.pers_blocks,
            date: formattedDate,
            time: formattedTime
          });

          await postMetrics(responseData.encrypt_id, {
            date: formattedDate,
            time: formattedTime,
            pers_parameters: responseData.pers_blocks,
            video_url: window.location.href
          });
        } else {
          console.error('Short link not found in the URL');
        }

        setShowPopup(false);
      } catch (error) {
        console.error('Error fetching project details:', error);
        setShowPopup(false);
      }
    };

    fetchProjectDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (styles && styles?.background) {
      if (styles.background?.backgroundColor) {
        document.body.style.background = styles.background.backgroundColor;
      } else if (styles.background?.backgroundImage) {
        document.body.style.background = styles.background.backgroundImage;
        document.body.style.backgroundAttachment = 'fixed';
        document.body.style.backgroundSize = 'cover';
        document.body.style.backgroundRepeat = 'no-repeat';
      }
    }

    return () => {
      document.body.style.background = '';
      document.body.style.backgroundAttachment = '';
      document.body.style.backgroundSize = '';
      document.body.style.backgroundRepeat = '';
    };
  }, [styles]);

  const handleTimeUpdate = () => {
    setCurrentTime(Math.floor(videoRef.current.currentTime));
  };

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.addEventListener('timeupdate', handleTimeUpdate);

      return () => {
        video.removeEventListener('timeupdate', handleTimeUpdate);
      };
    }
  }, []);

  const handleClickPause = async () => {
    await postVideoStats(currentTime, window.location.href);
  };

  const sendClick = async button => {
    await postClicksStats(encryptId, {
      date: metrics.date,
      time: metrics.time,
      video_url: metrics.video_url,
      pers_parameters: metrics.pers_parameters,
      clicks: [button]
    });
  };

  const memoizedTargetButtons = useMemo(() => {
    return targetButtons.map((button, index) => {
      const isProtocolPresent = /^[a-zA-Z][a-zA-Z\d+\-.]*:/.test(button.link);
      const link = isProtocolPresent ? button.link : `https://${button.link}`;

      return (
        <StyledButton
          onClick={() => {
            sendClick(button.name);
          }}
          key={index}
          btn={styles?.button}
          href={link}
          rel="noreferrer"
          target="_blank"
        >
          {button.name}
        </StyledButton>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetButtons, styles?.button]);

  useEffect(() => {
    let hideControlsTimeout;
    if (showControls) {
      hideControlsTimeout = setTimeout(() => {
        setShowControls(false);
      }, 1000);
    }
    return () => {
      clearTimeout(hideControlsTimeout);
    };
  }, [showControls]);

  const handlePlayPause = () => {
    const videoElement = videoRef.current;
    if (isPlaying) {
      videoElement.pause();
      handleClickPause();
    } else {
      videoElement.play();
    }
    setIsPlaying(!isPlaying);
    setShowControls(true);
  };

  return (
    <div className="player-wrapper">
      <header className="header">
        <img className="logo" src={logo} alt="Логотип ПЕРСИКОММ" />
        <div className="title-wrapper">
          <h1 className="header-title">{title}</h1>
          <p>{description}</p>
        </div>
      </header>
      <div className="player-content" onMouseMove={() => setShowControls(true)}>
        <video
          ref={videoRef}
          width="320"
          height="240"
          className="video-player"
          preload="auto"
          playsInline
          poster={posterUrl || poster}
          src={videoBlobUrl} 
        />
        <button
          className={`controls ${showControls || !isPlaying ? '' : 'hidden'}`}
          onClick={handlePlayPause}
        >
          <div className="icon">{isPlaying ? <IconPause /> : <IconPlay />}</div>
        </button>
      </div>
      <div className="button-group">{memoizedTargetButtons}</div>
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <h2>Подождите, идет загрузка...</h2>
          </div>
        </div>
      )}
    </div>
  );
};

export default Player;
